<template>
  <div class="pad-home">
    <HeaderBar title="优惠码兑换" :showLeft="true"></HeaderBar>
    <div class="coupon-code">
      <div class="coupon-code-input">
        <input type="text"
               v-model="couponcode"
               placeholder="请输入优惠码"
               @change="change" @blur="inputLoseFocus()">
      </div>
      <div :class="[couponcode?'coupon-code-exchange-active':'coupon-code-exchange']"
           @click="exchange">
        确认兑换
      </div>
    </div>
  </div>
</template>

<script>
import HeaderBar from '@/components/headerbar/headerbar';
import { getStore } from "@/lib/storage.js";
import { exchangeCounptList } from '@/service/';
import { Dialog } from "vant";
// import $ from 'jquery'
export default {
  name: 'exchangecoupon',
  components:{
    HeaderBar,
    [Dialog.Component.name]: Dialog.Component
  },
  data () {
    return {
      couponcode: ''
    }
  },
  computed: {
    
  },
  methods: {
    change () {

    },
   inputLoseFocus(){
				window.scroll(0, 0)
       },
      //  let userid = JSON.parse(getStore("userInfo")).ID;
       //首页查询优惠券
   async exchange(){
    //  $(".coupon-code-exchange-active").css({"background-color": "red"});
    // this.couponcode=
      let userid = JSON.parse(getStore("userInfo")).ID;
      let res = await exchangeCounptList(userid,this.couponcode);
         console.log(res)
         if(res&&res.code==1){
        Dialog.alert({
        title: '提示',
        message: '优惠券兑换成功'
      }).then(() => {
      
      });
         }
      // console.log(`exchange the code `)
    }
  }
}
</script>
<style lang="less" scoped>
@import '../../styles/mixin.less';

.pad-home{
  padding-top: 94px;
}
.coupon {
  &-code {
    .size(690px, 722px);
    margin: 16px auto;
    padding-top: 400px;
    background: url('../../assets/coupon_code.png') 0 0 no-repeat;
    background-size: 690px 722px;
    box-shadow: 0 12px 36px rgba(0, 0, 0, 0.1);
    box-sizing: border-box;
    border-radius: 20px;

    &-input {
      // width: 80%;
      display: flex;
      align-items: center;
      padding: 40px 0;
      margin: 0 auto;
      justify-content: center;
      font-family: 'PingFangSC-Regular';
      font-size: 30px;
      color: #999999;
      text-align: center;
      line-height: 42px;
      text-align: center;
      border-bottom: 2px dashed #ccc;

      input {
        text-align: center;
        font-size: 30px;
        font-family: 'PingFangSC-Medium';
        font-weight: 500;
        color: rgba(12, 170, 50, 1);
        line-height: 42px;
      }
    }

    &-exchange {
      width: 340px;
      height: 80px;
      margin: 58px auto 60px;
      background: #ccc;
      border-radius: 4px;
      font-size: 30px;
      font-family: 'PingFangSC-Medium';
      font-weight: 500;
      color: rgba(255, 255, 255, 1);
      line-height: 80px;
      text-align: center;
    }
    &-exchange-active {
      width: 340px;
      height: 80px;
      margin: 58px auto 60px;
      background: linear-gradient(
        90deg,
        rgba(218, 250, 226, 1) 0%,
        rgba(12, 170, 50, 1) 100%
      );
      border-radius: 4px;
      font-size: 30px;
      font-family: 'PingFangSC-Medium';
      font-weight: 500;
      color: rgba(255, 255, 255, 1);
      line-height: 80px;
      text-align: center;
    }
  }
}

input::-webkit-input-placeholder {
  /* WebKit browsers */
  color: #999;
}

input:-moz-placeholder {
  /* Mozilla Firefox 4 to 18 */
  color: #999;
  opacity: 1;
}

input::-moz-placeholder {
  /* Mozilla Firefox 19+ */
  color: #999;
  opacity: 1;
}

input:-ms-input-placeholder {
  /* Internet Explorer 10+ */
  color: #999;
}
</style>
